
* {
  box-sizing: border-box;
}

html, body {
  display: block;
  position: relative;
  height: 100%;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.iml-page-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  // overflow: auto;

  & > * {
    flex: 1;
  }

  & > :first-child {
    flex: 0 0 auto;
    height: 100vh;
  }

  & > .iml-page-content {
    // display: flex;
    position: relative;
    width: 50%;
    height: 100vh;
    overflow: auto;
    padding-top: 64px;
    // padding-bottom: 20px;

    & > * {
      // flex: 1;
      flex-grow: 1;
    }
  }
}

.iml-page-content-1 {
  // display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-top: 64px;
  // padding-bottom: 20px;

  & > * {
    // flex: 1;
    flex-grow: 1;
  }
}

.iml-layout-container {
  display: block;
  position: relative;
  width: 1200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.iml-card {
  border-radius: 10px;
  overflow: auto;
  background-color: white;
  margin: 15px;

  .iml-card-content {
    padding: 15px;
    padding-bottom: 0;

    &.iml-content-last {
      padding-bottom: 10px;
    }
  }
}

.iml-search-input {
  .mdc-text-field--outlined {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 28px;
    transition: background-color 0.1 ease-in-out;

    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.025);
    }

    --mdc-outlined-text-field-container-shape: 28px !important;
    --mdc-outlined-text-field-outline-color: transparent !important;
    --mdc-outlined-text-field-hover-outline-color: white !important;
    --mdc-outlined-text-field-focus-outline-color: white !important;
  }
}

.mat-mdc-menu-panel {
  --mat-menu-container-shape: 15px;
}

// 100% width buttons
.iml-full-width {
  .mdc-button__label {
    width: 100%;
    line-height: normal; // TODO: check
  }
}
