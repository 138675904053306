// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@mixin iml-custom-colors($theme) {
	.iml-color-text {
		color: mat.get-theme-color($theme, foreground, text);
	}

  .iml-primary-text {
    color: mat.get-theme-color($theme, primary, default);
  }

  .iml-text--primary-900 {
    color: mat.get-theme-color($theme, primary, 900);
  }

  .iml-accent-text {
    color: mat.get-theme-color($theme, accent, A200); // A700
  }

	.iml-color-secondary-text {
		color: mat.get-theme-color($theme, foreground, secondary-text);
	}

  // :root {
  --iml-primary-color: #{mat.get-theme-color($theme, primary, default)};
  --iml-primary-color-50: #{mat.get-theme-color($theme, primary, 50)};
  --iml-accent-color: #{mat.get-theme-color($theme, accent, A100)};
  --iml-warning-color: #{mat.get-theme-color($theme, warn, default)};
  // }
}

// Importing styles
@import '@typeform/embed/build/css/widget.css';

//Themes
@import './css/themes/iml-blue.scss';

.iml-light-blue--theme {
  @include mat.all-component-themes($iml-blue-theme);
  @include iml-custom-colors($iml-blue-theme);
}

// Layout
@import './css/layout.scss';
@import './css/icons.scss';
